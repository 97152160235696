import { infoCardTypes } from '~~/sharedUtils/infoCardTypes'

export const migrations = [
  {
    description: 'Add missing convertYAxisToPercentage prop',
    from: '0.0',
    to: '0.1',
    up (schema) {
      const cardFullNames = ['Enterprise - Day click rate', 'Enterprise - Day VS Last Year', 'Enterprise - Day VS 2019']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.convertYAxisToPercentage = true
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      delete newSchema.props.convertYAxisToPercentage
      return newSchema
    },
  },
  {
    description: 'Set convertYAxisToPercentage to false for "Enterprise - Per click cost"',
    from: '0.1',
    to: '0.2',
    up (schema) {
      const cardFullNames = ['Enterprise - Per click cost']
      console.log({ schema })
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.convertYAxisToPercentage = false
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      newSchema.props.convertYAxisToPercentage = true
      return newSchema
    },
  },
  {
    description: 'Add missing prop "dataIdentifier" to "Enterprise - Per click cost"',
    from: '0.2',
    to: '0.3',
    up (schema) {
      const cardFullNames = ['Enterprise - Per click cost']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.dataIdentifier = 'per_click_cost'
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      delete newSchema.props.dataIdentifier
      return newSchema
    },
  },
  {
    description: 'Remove URL prop from all cards',
    from: '0.3',
    to: '0.4',
    up (schema) {
      const newSchema = { ...schema }
      delete newSchema.props?.url
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      newSchema.props.url = { text: '', value: '' }
      return newSchema
    },
  },
  {
    description: 'Remove title typo from EBITDA cards',
    from: '0.4',
    to: '0.5',
    up (schema) {
      const cardFullNames = ['EBITDA - Actual & Budget', 'EBITDA - Budget', 'EBITDA - Actual']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.title = newSchema.props.title.replace('EBIDTA', 'EBITDA')
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      return newSchema
    },
  },
  {
    description: 'Replace Net revenue and OH budget api endpoints to update links',
    from: '0.5',
    to: '0.6',
    up (schema) {
      const cardFullNames = ['EBITDA - Sum Net Revenue', 'EBITDA - Sum Budget OH']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.apiEndpoint = newSchema.props.apiKeys[0]
      }
      return newSchema
    },
    down (schema) {
      const cardFullNames = ['EBITDA - Sum Net Revenue', 'EBITDA - Sum Budget OH']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.apiEndpoint = 'EBITDA_all'
      }
      return newSchema
    },
  },
  {
    description: 'Replace Enterprise with Etraveli',
    from: '0.6',
    to: '0.7',
    up (schema) {
      const cardFullNames = ['Enterprise - Flygresor.se Search Market Share']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.fullName = 'ETraveli - Flygresor.se search market share'
      }
      return newSchema
    },
    down (schema) {
      const cardFullNames = ['ETraveli - Flygresor.se search market share']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.fullName = 'ETraveli - Flygresor.se search market share'
      }
      return newSchema
    },
  },
  {
    description: 'Rename table top 6 chart properly',
    from: '0.7',
    to: '0.8',
    up (schema) {
      const cardFullNames = ['Enterprise - OTA shares - Top 6 brands']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.title = 'OTA shares - Top 6 brands per day'
      }
      return newSchema
    },
    down (schema) {
      const cardFullNames = ['Enterprise - OTA shares - Top 6 brands']
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.title = 'OTA shares - Top 6 brands per day'
      }
      return newSchema
    },
  },
  {
    description: 'Estimated spend pnl only valid in current month',
    from: '0.8',
    to: '0.9',
    up (schema) {
      const cardFullNames = ['CSM - Planned vs Est', 'CSM - Estimated market spend']
      const newSchema = { ...schema }
      if (schema.fullName === cardFullNames[0]) {
        newSchema.props.currentSortBy.index = 1
      } else if (schema.fullName === cardFullNames[1]) {
        newSchema.props.currentSortBy.index = 0
        newSchema.props.sortByItems = ['Current month']
      }
      return newSchema
    },
    down (schema) {
      const cardFullNames = ['CSM - Planned vs Est', 'CSM - Estimated market spend']
      const newSchema = { ...schema }
      if (schema.fullName === cardFullNames[0]) {
        newSchema.props.currentSortBy.index = 1
      } else if (schema.fullName === cardFullNames[1]) {
        newSchema.props.currentSortBy.index = 2
      }
      return newSchema
    },
  },
  {
    description: 'Graph readability enhanced on desktop',
    from: '0.9',
    to: '1.0',
    up (schema) {
      const cardFullNames = [
        'Enterprise - Per click cost',
        'ETraveli - Flygresor.se search market share',
        'ETraveli - METAs searches vs Flygresor.se market share',
        'Enterprise - OTA shares - Overview',
        'Enterprise - OTA shares - ETG brands',
        'Enterprise - OTA shares - Non ETG brands',
      ]
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.width = 'half'
      }
      return newSchema
    },
    down (schema) {
      const cardFullNames = [
        'Enterprise - Per click cost',
        'ETraveli - Flygresor.se search market share',
        'ETraveli - METAs searches vs Flygresor.se market share',
        'Enterprise - OTA shares - Overview',
        'Enterprise - OTA shares - ETG brands',
        'Enterprise - OTA shares - Non ETG brands',
      ]
      const newSchema = { ...schema }
      if (cardFullNames.includes(schema.fullName)) {
        newSchema.props.width = 'full'
      }
      return newSchema
    },
  },
  {
    description: 'Add permissions to all cards',
    from: '1.0',
    to: '1.1',
    up (schema) {
      // Find the card in the infoCardTypes array by fullName and add the permissions
      const newSchema = { ...schema }
      const card = infoCardTypes.find(card => card.fullName === schema.fullName)
      if (card) {
        newSchema.permission = card.permission
      } else {
        console.log(`Card not found in infoCardTypes: ${schema.fullName}`)
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      delete newSchema.permission
      return newSchema
    },
  },
  {
    description: 'Add multipleY prop to "ETraveli - METAs searches vs Flygresor.se market share" and set type to line',
    from: '1.1',
    to: '1.2',
    up (schema) {
      const newSchema = { ...schema }
      const cardName = 'ETraveli - METAs searches vs Flygresor.se market share'
      const card = infoCardTypes.find(card => card.fullName === cardName)
      if (card) {
        newSchema.props.type = 'line'
        newSchema.props.multipleY = true
      } else {
        console.log(`Card not found in infoCardTypes: ${schema.fullName}`)
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      const cardName = 'ETraveli - METAs searches vs Flygresor.se market share'
      const card = infoCardTypes.find(card => card.fullName === cardName)
      if (card) {
        delete newSchema.props.multipleY
        newSchema.props.type = 'multipleY'
      }
      return newSchema
    },
  },
  {
    description: 'Add convertPercentageToTime prop to "Dash - Time of Profit"',
    from: '1.2',
    to: '1.4',
    up (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profit'
      if (newSchema.fullName === cardName) {
        newSchema.props.convertPercentageToTime = true
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profit'
      const card = infoCardTypes.find(card => card.fullName === cardName)
      if (card) {
        delete newSchema.props.convertPercentageToTime
      }
      return newSchema
    },
  },
  {
    description: 'Update "Dash - Time of profit" title to be "Dash - Time of profitability"',
    from: '1.4',
    to: '1.5',
    up (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profit'
      if (newSchema.fullName === cardName) {
        newSchema.props.title = 'Dash - Time of profitability'
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profitability'
      const card = infoCardTypes.find(card => card.fullName === cardName)
      if (card) {
        newSchema.props.title = 'Dash - Time of profit'
      }
      return newSchema
    },
  },
  {
    description: 'Update "Dash - Time of profitability" props',
    from: '1.5',
    to: '1.6',
    up (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profitability'
      if (newSchema.fullName === cardName) {
        // Move
        delete newSchema.props.type
        delete newSchema.props.convertPercentageToTime
        newSchema.props.baseChart = {
          type: 'line',
          convertPercentageToTime: true,
        }
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profitability'
      const card = infoCardTypes.find(card => card.fullName === cardName)
      if (card) {
        delete newSchema.props.baseChart
        newSchema.props.type = 'line'
        newSchema.props.convertPercentageToTime = true
      }
      return newSchema
    },
  },
  {
    description: 'Update "Dash - Time of profitability" props',
    from: '1.6',
    to: '1.7',
    up (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profitability'
      if (newSchema.fullName === cardName) {
        newSchema.title = 'Time of profitability'
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profitability'
      if (newSchema.fullName === cardName) {
        newSchema.title = 'Dash - Time of profit'
      }
      return newSchema
    },
  },
  {
    description: 'Update "Dash - Time of profitability" props',
    from: '1.7',
    to: '1.8',
    up (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profitability'
      if (newSchema.fullName === cardName) {
        delete newSchema?.baseChart?.convertPercentageToTime
        newSchema.props.convertPercentageToTime = true
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      const cardName = 'Dash - Time of profitability'
      if (newSchema.fullName === cardName) {
        delete newSchema.props.convertPercentageToTime
        newSchema.baseChart.convertPercentageToTime = true
      }
      return newSchema
    },
  },
  {
    description: 'Add numberOfDaysToSubtractWhenChangingDate prop to Google Sheets cards',
    from: '1.8',
    to: '1.9',
    up (schema) {
      const newSchema = { ...schema }
      const cards = [
        'Google Spreadsheet - Top of Mind - Brand Awareness',
        'Google Spreadsheet - Top of Mind - Ad recall',
        'Google Spreadsheet - In mind - Brand Awareness',
      ]
      if (cards.includes(schema.fullName)) {
        newSchema.props.numberOfDaysToSubtractWhenChangingDate = 365
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      const cards = [
        'Google Spreadsheet - Top of Mind - Brand Awareness',
        'Google Spreadsheet - Top of Mind - Ad recall',
        'Google Spreadsheet - In mind - Brand Awareness',
      ]
      if (cards.includes(schema.fullName)) {
        delete newSchema.props.numberOfDaysToSubtractWhenChanging
      }
      return newSchema
    },
  },
  {
    description: 'Clear recent items cache',
    from: '1.9',
    to: '1.10',
    up (schema) {
      localStorage.removeItem('/api/analytics/events/names-recentItems')
      localStorage.removeItem('/api/analytics/pages/names-recentItems')
      return schema
    },
    down (schema) {
      return schema
    },
  },
  {
    description: 'Add isAddedToDashboard prop to Analytics cards',
    from: '1.10',
    to: '1.11',
    up (schema) {
      const newSchema = { ...schema }
      if (newSchema.type === 'Analytics') {
        newSchema.props.isAddedToDashboard = false
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      if (newSchema.type === 'Analytics') {
        delete newSchema.props.isAddedToDashboard
      }
      return newSchema
    },
  },
  {
    description: 'Add displayValuesAs prop to Analytics cards',
    from: '1.11',
    to: '2',
    up (schema) {
      const newSchema = { ...schema }
      if (newSchema.type === 'Analytics') {
        newSchema.props.displayValuesAs = { label: 'Absolute', value: 'absolute' }
        console.log('Updated schema', newSchema)
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      if (newSchema.type === 'Analytics') {
        delete newSchema.props.displayValuesAs
      }
      return newSchema
    },
  },
  {
    description: 'Add legendLarge prop to ETraveli - Meta all market share',
    from: '2',
    to: '2.1',
    up (schema) {
      const newSchema = { ...schema }
      if (newSchema.fullName === 'ETraveli - Meta all market share') {
        newSchema.props.legendLarge = true
      }
      return newSchema
    },
    down (schema) {
      const newSchema = { ...schema }
      if (newSchema.fullName === 'ETraveli - Meta all market share') {
        delete newSchema.props.legendLarge
      }
      return newSchema
    },
  },
]

export const schemaVersion = '2.1'

/**
 * @param {object} [req] schema - the current schema
 * @param {string} [req] fromVersion - the current version
 * @param {string} [req] toVersion - the desired version
 * @return {object}
 */
export function migrate (schema, fromVersion, toVersion) {
  const direction = upOrDown(fromVersion, toVersion)
  if (direction === 'same') {
    return schema
  }
  const currentMigration = migrations.find(
    migration => migration[direction === 'up' ? 'from' : 'to'] === fromVersion,
  )
  if (!currentMigration) {
    console.log(`Migration strategy not found ${fromVersion} to ${toVersion}`)
    return schema
  }
  const newSchema = currentMigration[direction](schema)
  const nextVersion = direction === 'up' ? currentMigration.to : currentMigration.from

  console.log(`Migrating from ${fromVersion} to ${toVersion}`)

  return migrate(newSchema, nextVersion, toVersion)
}

function upOrDown (fromVersion, toVersion) {
  const fromNumbers = fromVersion.split('.').map(el => Number(el))
  const toNumbers = toVersion.split('.').map(el => Number(el))
  for (let i = 0; i < fromNumbers.length; i++) {
    if (fromNumbers[i] < toNumbers[i]) {
      return 'up'
    }
    if (fromNumbers[i] > toNumbers[i]) {
      return 'down'
    }
  }
  return 'same'
}
